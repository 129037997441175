import { v4 as uuid } from "uuid";
import { camelCase, get, isNil, omitBy, snakeCase } from "lodash";
import { parseCookies } from "nookies";
import { PORTED_LINKS, ALLOWED_HOSTS } from "globalConfig";
import { parse } from "url";
import { Options as ReactToastOptions } from "react-toast-notifications";
import {
  CamelToSnakeCaseNested,
  SnakeToCamelCaseNested,
} from "@literati/kids-api";

export const makeKidsUrl = (path: string) => {
  if (!path) {
    console.warn(
      "makeUrl was called without a path. If a path is not needed, use process.env instead, otherwise it may be missing from an API response"
    );
    return "";
  }
  return path.startsWith("http") || PORTED_LINKS.includes(path)
    ? path
    : `${process.env.NEXT_PUBLIC_KIDS_URL}${path}`;
};

export const makeUrl = makeKidsUrl;

/**
 * Limits redirects to Literati domains in production.
 * Intended to guard against an Open Redirect vulnerability.
 * See https://www.invicti.com/blog/web-security/open-redirection-vulnerability-information-prevention/
 */
export const sanitizeRedirectPath = (path: string) => {
  if (!path) {
    return;
  }
  const parsed = parse(path, false);
  if (parsed.protocol) {
    if (
      parsed.protocol !== "https:"
      || !ALLOWED_HOSTS.includes(String(parsed.hostname))
    ) {
      return "";
    }
  }
  return path;
};

export const getRootApiURL = () => {
  let baseUrl;

  if (typeof window === "undefined") {
    baseUrl = process.env.CLUSTER_KIDS_URL || process.env.NEXT_PUBLIC_KIDS_URL;
  } else {
    if (process.env.NODE_ENV === "production") {
      const origin = window.location.origin || process.env.NEXT_PUBLIC_KIDS_URL;
      const rootDomainIndex = origin?.indexOf("literati.") ?? -1;
      baseUrl =
        rootDomainIndex >= 0
          ? `https://kids-api.${origin?.substring(rootDomainIndex)}`
          : process.env.NEXT_PUBLIC_KIDS_URL;
    } else {
      baseUrl = process.env.NEXT_PUBLIC_KIDS_URL;
    }
  }
  return baseUrl;
};

export const apiFetch = async (path: string, options: RequestInit = {}) => {
  const headers = get(options, "headers", new Headers());

  const composedOptions: RequestInit = {
    ...options,
    headers,
    credentials: "include",
  };

  const baseUrl = getRootApiURL();

  const url = path.startsWith("http") ? path : `${baseUrl}${path}`;

  // eslint-disable-next-line no-console
  process.env.NODE_ENV !== "production" &&
    console.debug(
      `Fetching API ${url}`,
      `Method: ${composedOptions?.method || "GET"}`
    );
  return fetch(url, composedOptions);
};

export const apiFetchJson = async (...args: Parameters<typeof apiFetch>) => {
  return apiFetch(...args).then((res) =>
    res.ok ? res.json() : Promise.reject(res)
  );
};

export const formatToast = (message: string, options: ReactToastOptions) => {
  const customId = uuid();
  return [message, { ...options, id: customId, customId }] as const;
};

/** used to perform actions on keys that are dot notated for nested values **/
const splitDot = <Value>(
  k: string,
  v: Value,
  fn: (p: string, v: Value) => string
) =>
  k
    .split(".")
    .map((p) => fn(p, v))
    .join(".");

export const deepMapKeys = (
  obj: unknown,
  fn: (k: string, v: unknown) => string,
  split = true
): unknown => {
  return Array.isArray(obj)
    ? obj.map((val) => deepMapKeys(val, fn))
    : obj && typeof obj === "object"
    ? Object.keys(obj).reduce((acc, current) => {
        const val = (obj as Record<string, unknown>)[current];
        const key = split ? splitDot(current, val, fn) : fn(current, val);
        acc[key] =
          val !== null && typeof val === "object" ? deepMapKeys(val, fn) : val;
        return acc;
      }, {} as Record<string, unknown>)
    : obj;
};

export const deepMapValues = (
  obj: object,
  fn: (obj: object) => object
): unknown => {
  const x = fn(obj);
  return Array.isArray(x)
    ? x.map((val) => deepMapValues(val, fn))
    : typeof fn(obj) === "object"
    ? Object.keys(x).reduce((acc, current) => {
        const key = current;
        const val = (x as Record<string, unknown>)[current];
        acc[key] =
          val !== null && typeof val === "object"
            ? deepMapValues(val, fn)
            : val;
        return acc;
      }, {} as Record<string, unknown>)
    : x;
};

export const mapKeysCamelCase = <O>(obj: O) =>
  deepMapKeys(obj, (k) => camelCase(k)) as SnakeToCamelCaseNested<O>;

export const mapKeysSnakeCase = <O>(obj: O) =>
  deepMapKeys(obj, (k) => snakeCase(k)) as CamelToSnakeCaseNested<O>;

export const assetUrl = (path: string) => {
  const assetPrefix = process.env.ASSET_PREFIX;
  return assetPrefix ? `${assetPrefix}${path}` : path;
};

export const apiPost = (path: string, body: RequestInit["body"]) => {
  const cookies = parseCookies();
  const headers = new Headers({
    "X-CSRFToken": cookies["csrftoken-kids"],
    "Content-Type": "application/json",
  });
  const options: RequestInit = {
    method: "POST",
    headers,
    body,
  };
  return apiFetch(path, options);
};

export const objToPairStr = (
  obj: Record<string, string>,
  separator: string,
  delimiter = ", "
) => {
  const joinedPairs: string[] = [];
  Object.entries(obj).map((pair) => joinedPairs.push(pair.join(separator)));
  return joinedPairs.join(delimiter);
};

export const arrayToReadableString = (arr: unknown[], conjunction = "and") => {
  const preceding = arr.slice(0, -1);
  const last = arr.slice(-1);
  return [preceding.join(", "), last].join(
    arr.length > 1 ? ` ${conjunction} ` : ""
  );
};

export const throttle = <Args extends unknown[]>(
  callback: (...args: Args) => unknown,
  delay: number,
  immediate?: boolean
) => {
  let timerId: NodeJS.Timeout | undefined;
  return (...args: Args) => {
    if (timerId) {
      return;
    }

    const callNow = immediate && !timerId;

    callNow && callback(...args);

    timerId = setTimeout(function () {
      !callNow && callback(...args);
      timerId = undefined;
    }, delay);
  };
};

export const splitCurrency = (value: string) =>
  value.replace("$", "").split(".");

export const makeQueryString = (
  p: Record<string, string | number | undefined | null>
) => {
  const joinedParams = new URLSearchParams(
    // Filter out null / undefined values
    omitBy(p, isNil) as Record<string, string>
  ).toString();
  return joinedParams.length ? `?${joinedParams}` : "";
};

export const getApplicabilityStr = ({
  humanApplicabilityList,
}: {
  humanApplicabilityList: unknown[];
}) => {
  return arrayToReadableString(humanApplicabilityList);
};

export const hexToRgba = (hex: string, opacity: string | number = 1) => {
  const color = [hex.slice(1, 3), hex.slice(3, 5), hex.slice(5, 7)]
    .map((el) => parseInt(el, 16))
    .join(",");
  return `rgba(${color}, ${opacity})`;
};
